.m-0 { margin: 0;}
.m-1 { margin: 0.5em;}
.m-2 { margin: 1em;}
.m-3 { margin: 1.5em;}
.m-4 { margin: 2em;}
.m-5 { margin: 2.5em;}
.m-6 { margin: 3em;}

.mt-0 { margin-top: 0;}
.mt-1 { margin-top: 0.5em;}
.mt-2 { margin-top: 1em;}
.mt-3 { margin-top: 1.5em;}
.mt-4 { margin-top: 2em;}
.mt-5 { margin-top: 2.5em;}
.mt-6 { margin-top: 3em;}

.mr-0 { margin-right: 0;}
.mr-1 { margin-right: 0.5em;}
.mr-2 { margin-right: 1em;}
.mr-3 { margin-right: 1.5em;}
.mr-4 { margin-right: 2em;}
.mr-5 { margin-right: 2.5em;}
.mr-6 { margin-right: 3em;}

.mb-0 { margin-bottom: 0;}
.mb-1 { margin-bottom: 0.5em;}
.mb-2 { margin-bottom: 1em;}
.mb-3 { margin-bottom: 1.5em;}
.mb-4 { margin-bottom: 2em;}
.mb-5 { margin-bottom: 2.5em;}
.mb-6 { margin-bottom: 3em;}

.ml-0 { margin-left: 0;}
.ml-1 { margin-left: 0.5em;}
.ml-2 { margin-left: 1em;}
.ml-3 { margin-left: 1.5em;}
.ml-4 { margin-left: 2em;}
.ml-5 { margin-left: 2.5em;}
.ml-6 { margin-left: 3em;}
