footer {
  background-color: var(--sec-color);
  color: var(--f-color-inv);
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;

  .grid--container {
    display: grid;
    grid-template-columns: 1fr;

    .logo--holder {
      align-content: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      & img {
        height: 100px;
        width: auto;
      }
    }

    .social--links {
      align-content: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding-top: 4.5rem;

      & svg {
        fill: var(--pri-color);
        height: 1.5em;
        margin-right: 1em;
        width: 1.5em;

        &:hover,
        &:focus {
          fill: var(--wh-color);
        }
      }
    }

    & div {
      align-content: center;
      border-top: var(--bor);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto;
      min-width: 250px;
      padding: 0;
      text-align: left;

      & h5 {
        font-size: var(--heading-h4);
        font-weight: var(--h-font-wght);
      }

      & a {
        color: var(--f-color-inv);
        text-decoration: none;
        transition: all var(--dur) var(--trans);

        &:hover,
        &:focus {
          color: var(--pri-color);
          font-weight: var(--h-font-wght);
        }
      }


      & ul {
        line-height: 2;
        margin: .5rem 0 1rem;

        & li {
          margin-left: 0;

        }
      }
    }
  }

  .grid--container>div {
    padding: 1.5em;
    width: 100%;
  }

  .row-1,
  .row-2 {
    grid-template-columns: 1fr;
  }

  .grid--container>div.row-1,
  .grid--container>div.row-2 {
    border-top: 0;
  }

}

.footer--last--block {
  background-color: var(--sec-color-drk);
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  min-width: 100%;
  padding: 1rem 1rem .5rem;

  & div {
    margin: 0 auto;
  }

  & p,
  & a {
    font-size: 12px;
  }

  & p {
    padding-top: .3rem;
  }

  & ul {
    display: flex;
  }

  & a {
    color: var(--f-color-inv);
    overflow-wrap: normal;
    text-decoration: none;
    transition: all var(--dur) var(--trans);

    &:hover,
    &:focus {
      color: var(--pri-color);
      font-weight: var(--h-font-wght);
    }
  }

  & a:last-of-type {
    margin-left: .3rem;
  }
}

@media screen and (min-width: 797px) {
  footer {

    .grid--container {
      grid-gap: 1.5em;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto;
      max-width: 90vw;
      padding: 2rem;

      .grid--container>div {
        padding: 1.5rem;
      }

      .row-1 {
        display: grid;
        gap: 1rem;
        grid-column: span 2;
        grid-template-columns: repeat(2, 1fr);

        & div {
          border-top: 0;
          margin: 0 auto;
        }
      }

      .row-2 {
        display: grid;
        gap: 1rem;
        grid-column: span 2;
        grid-template-columns: repeat(3, 1fr);

        & div {
          align-content: center;
          border-top: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 0 auto;
          min-width: 250px;
          text-align: left;
        }
      }
    }

    .footer--last--block {
      grid-template-columns: 1fr 1fr;

      & ul {
        flex-direction: row wrap;
      }
    }
  }
}

@media screen and (min-width: 1023px) {
  footer {

    .grid--container {
      grid-gap: 2em;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto;
      max-width: 80vw;
      padding: 2rem;

      .grid--container>div {
        padding: 2.5rem;
      }

      .row-1 {
        display: grid;
        gap: 1rem;
        grid-column: span 2;
        grid-template-columns: repeat(2, 1fr);
      }

      .row-2 {
        display: grid;
        gap: 1rem;
        grid-column: span 2;
        grid-template-columns: repeat(3, 1fr);

        & div {
          min-width: 350px;
        }
      }

      .logo--holder {
        & img {
          height: 180px;
        }
      }
    }

    .footer--last--block {
      grid-template-columns: 1fr 1fr;

      & ul {
        flex-direction: row wrap;
      }
    }
  }
}
