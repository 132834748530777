header {
  background-color: var(--sec-color);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  overflow: hidden;
  position: relative;

  & div {
    display: absolute;
    height: 100%;
    left: 0;
    padding: 1rem 2rem;
    top: 0;
    width: 100%;
    z-index: 1;

    & h1 {
      color: var(--pri-color);
      text-align: center;
      z-index: 99;
    }

    .header--pre--text {
      color: var(--pri-color);
      font-size: var(--heading-h6);
      font-weight: var(--h-font-wght);
      text-align: center;
      text-transform: uppercase;
      z-index: 99;
    }

    .header--text {
      color: var(--pri-color);
      font-size: var(--header-text);
      font-weight: var(--h-font-wght);
      margin: 0 auto 1.5rem;
      text-align: center;
      text-transform: uppercase;
      z-index: 99;
    }

    .header--sub--text {
      color: var(--pri-color);
      margin: 0 auto;
      max-width: var(--s-width);
      text-align: center;
      z-index: 99;
    }
  }
}

@media screen and (min-width: 797px) {
  header {
    & div {
      padding: 6rem 1rem;
    }
  }
}

@media screen and (min-width: 1023px) {
  header {
    & div {
      padding: 8rem 1rem;
    }
  }
}
