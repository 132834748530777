main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 100%;
  padding: 3rem 0;

  & a {
    color: var(--pri-color-drk);
    font-style: italic;
    font-weight: var(--h-font-wght);
    text-decoration: none;
    transition: all var(--dur) var(--trans);

    &:focus,
    &:hover {
      color: var(--sec-color-lgt);
      font-style: unset;
    }
  }

  & section {
    margin: 0 auto;
    max-inline-size: var(--m-width);
    padding: 0 1rem;
  }

  & article {
    margin: 0 auto;
    max-inline-size: var(--m-width);
    padding: 0 2rem 0 1rem;
  }

  & section article figure img {
    float: right;
    margin-bottom: 0.5em;
    margin-left: 1em;
    width: 250px;
  }

  & ul {
    list-style: none outside none;
    margin-left: 0;
    white-space: nowrap;

    & li {
      list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
      overflow-wrap: keep-all;
      white-space: normal;
      word-break: keep-all;
    }

    & li:before {
      content: '';
      margin-right: 0.6rem;
    }
  }

  .policy--notice {
    font-size: var(--small);
    opacity: .7;

    & a {
      font-size: inherit;
    }
  }

  .textimage--wrapper {
    margin-left: 50%;
    padding: 5vh 1rem;
    transform: translateX(-50%);
    width: 100vw;
  }

  .textimage--block--left,
  .textimage--block--right {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }

  .two--image--block {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }

  .four--image--block {
    align-items: center;
    display: grid;
    gap: 0;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
  }

  .four--image--block--item {
    position: relative;

    & img {
      height: 350px;
      width: 100%
    }

    & h3 {
      bottom: 1rem;
      color: var(--f-color-inv);
      left: 1rem;
      position: absolute;
      z-index: 1;
    }
  }
} 

@media screen and (min-width: 1023px) {
  main {
    .textimage--block--left {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 1.5rem;
    }

    .two--image--block {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 1.5rem;
    }

    .four--image--block {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 1.5rem;
    }

    .textimage--block--right {
      direction: rtl;
      grid-template-columns: 1fr 1fr;
    }

    .textimage--block--right .text {
      direction: ltr;
    }
  }
}
