/* General navbar styling */
.navbar,
.navbar-menu,
.navbar-brand,
.navbar-item.has-dropdown {
  background-color: var(--sec-color);
}

.navbar [aria-current="page"] {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
}

.navbar-brand {
  height: 80px;
}

.navbar-brand img {
  height: 100px;
  width: auto;
}

.navbar-item.has-dropdown,
.navbar-item,
.navbar-link,
.navbar-dropdown-arrow {
  color: var(--f-color-inv);
}

.navbar-item.active {
  font-weight: 600;
  font-style: italic;
}

a {
  text-decoration: none;
}

.navbar-link.active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  background-color: var(--pri-color);
  color: var(--f-color-inv);
}

.navbar-dropdown {
  background-color: var(--sec-color);
  border-radius: var(--bor-rad);
  color: var(--f-color-inv);
}

.navbar-dropdown a.navbar-item:hover {
  background-color: var(--pri-color);
  color: var(--f-color-inv);
}

.navbar-item .icon:only-child,
.navbar-link .icon:only-child {
  margin-left: .25rem;
  margin-right: .25rem;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: var(--f-color-inv);
}

.navbar-burger {
  margin-right: 2rem;
}

.navbar-burger span {
  background-color: var(--f-color-inv);
}

.navbar-dropdown {
  display: none;
}

.navbar-dropdown.is-active {
  display: block;
}

.navbar-item.has-dropdown.is-active > .navbar-link {
  background-color: var(--pri-color-drk);
  color: var(--f-color-inv);
}

@media screen and (min-width: 1023px) {
  .navbar {
    align-content: center;
    display: flex;
    justify-content: center;
  }

  .navbar-brand {
    height: 80px;
  }

  .navbar-brand img {
    height: 120px;
    margin-right: 3rem;
    width: auto;
  }

  .navbar-menu {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .navbar-item.has-dropdown.is-active .navbar-link,
  .navbar-item.has-dropdown:focus .navbar-link,
  .navbar-item.has-dropdown:hover .navbar-link {
    background-color: var(--pri-color-drk);
    color: var(--f-color-inv);
  }

  .navbar-dropdown a.navbar-item:focus,
  .navbar-dropdown a.navbar-item:hover {
    background-color: var(--pri-color);
    color: var(--f-color-inv);
  }
}
