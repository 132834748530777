.contact {
  padding: 2rem .6rem;

  .contact--block {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 797px) {
  .contact {
    width: 100%;

    .contact--block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.map-container {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto 1rem;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

