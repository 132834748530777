nav.pagination  {
  margin-top: 3rem;
  padding: 0;

  & a {
    background: var(--sec-color);
    border: 2px solid transparent;
    border-radius: var(--bor-rad);
    box-shadow: var(--shadow);
    color: var(--wh-color);
    margin: 0;
    padding: .6em 1.2em;

    &:focus,
    &:hover {
      border: 2px solid var(--pri-color);
      border-radius: var(--bor-rad);
      box-shadow: var(--shadow-lg);
      text-decoration: none;

    }

    .icon {
      margin-right: 0;
    }
  }
}