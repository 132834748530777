:root {
  --auto-grid-min-size: 320px;
  --auto-grid-min-size-compact: 220px;
}

.auto--grid--fill {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size, 16rem), 1fr));
  margin-left: auto;
  margin-right: auto;
  place-content: center;
  width: 100%;
}

.auto--grid--fill--compact {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size-compact, 16rem), 1fr));
  margin-left: auto;
  margin-right: auto;
  place-content: center;
  width: 100%;
}

.auto--grid--fit {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--auto-grid-min-size, 16rem), 1fr));
  margin-left: auto;
  margin-right: auto;
  place-content: center;
  width: 100%;
}

@media screen and (min-width: 797px) {
  .auto--grid--fill {
    width: 85vw;
  }

  .auto--grid--fill--compact {
    width: 85vw;
  }

  .auto--grid--fit {
    width: 85vw;
  }
}

@media screen and (min-width: 1023px) {
  .auto--grid--fill {
    grid-gap: 2rem;
    width: 70vw;
  }

  .auto--grid--fill--compact {
    grid-gap: 2rem;
    width: 70vw;
  }

  .auto--grid--fit {
    grid-gap: 2rem;
    width: 70vw;
  }
}