.news--list--block {
    background-color: var(--g-color);
    border: var(--bor);
    border-radius: var(--bor-rad);
    box-shadow: var(--shadow-sm);
    color: var(--f-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    text-align: left;

    & img, & picture {
      margin-bottom: 0;
    }

    .container {
      display: flex;
      flex-direction: column;
      padding: 1em;
      justify-content: end;
    }
}

.tags {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 3rem;
  a {
    background: var(--sec-color);
    border: 2px solid transparent;
    border-radius: var(--bor-rad);
    box-shadow: var(--shadow);
    color: var(--wh-color);
    margin-right: .5em;
    padding: .6em 1.2em;

    &:focus,
    &:hover {
      border: 2px solid var(--pri-color);
      border-radius: var(--bor-rad);
      box-shadow: var(--shadow-lg);
      color: var(--wh-color);
      text-decoration: none;
    }
  }
}
