.sitemap--list [aria-current="page"] {
  background-color: var(--g-color-2);
  font-style: italic;
  font-weight: 600;
}

.sitemap--list ul {
  display: flex;
  flex-direction: column;
  justify-content: left;
  line-height: 1.7;

  li::before,
  .sublist li::before {
    content: '*';
    margin-right: .4rem;
    position: relative;
    top: 5px;
  }

  li a {
    color: var(--f-color);
    font-style: none;
    font-weight: inherit;
    padding: .3rem;
    text-decoration: none;
    transition: all .35s var(--trans);

    &:focus,
    &:hover {
      background-color: var(--g-color-2);
      color: var(--f-color);
    }
  }
  ul {
    margin-left: 1rem;
  }
}
