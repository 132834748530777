button {
  background: transparent;
  border: var(--bor);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow);
  color: var(--pri-color);
  cursor: pointer;
  display: inline-block;
  margin: 0;
  margin-right: .6rem;
  outline: none;
  padding: .6em 1.2em;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all var(--dur) var(--trans);

  &:focus,
  &:hover {
    background-color: var(--pri-color);
    border-color: var(--pri-color);
    box-shadow: var(--shadow-lg);
    color: all var(--wh-color);
    text-decoration: none;
  }

  &:active {
    background: var(--pri-color-drk);
    border-color: var(--pri-color-drk);
  }

  &.full-width {
    min-width: 100%;
  }
}

.button {
  background-color: transparent;
  border: var(--bor);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow);
  color: var(--pri-color);
  cursor: pointer;
  display: inline-block;
  margin: 0 .6rem .6rem 0;
  outline: none;
  padding: .6em 1.2em;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all var(--dur) var(--trans);

  &:focus,
  &:hover {
    background-color: var(--pri-color);
    border-color: var(--pri-color);
    box-shadow: var(--shadow-lg);
    color: var(--f-color-inv);
    text-decoration: none;
  }

  &.active {
    background: var(--pri-color);
    border-color: var(--pri-color);

    &:focus,
    &:hover {
      background-color: var(--pri-color);
      border-color: var(--pri-color);
    }
  }

  &.full-width {
    min-width: 100%;
  }

  &.rounded {
    border-radius: 2rem;
  }
}

.button--primary {
  background-color: var(--sec-color);
  border: 2px solid transparent;
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow);
  color: var(--f-color-inv);
  cursor: pointer;
  display: inline-block;
  margin: 0 .6rem .6rem 0;
  outline: none;
  padding: 1ch 2ch;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all var(--dur) var(--trans);

  &:focus,
  &:hover {
    color: var(--f-color-inv);
    border: 2px solid var(--pri-color);
    border-radius: var(--bor-rad);
    box-shadow: var(--shadow-lg);
    text-decoration: none;
  }

  &:active {
    background: var(--pri-color);
    border-color: var(--pri-color);
    color: all var(--wh-color);
  }
}
