.frontpage--block {
  background-color: var(--bkgd-color);
  border: 1px dotted var(--pri-color);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-sm);
  color: var(--f-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem 0 0;
  text-align: left;

  & h2,
  & h3 {
    color: var(--sec-color);
    margin-top: 0;
    padding-left: 1rem;
  }

  & p {
    margin-bottom: auto;
    padding-left: 1rem;
  }
}

.slider {
  background-color: var(--g-color-1);
  padding: 2rem;
  margin-bottom: 0;
  h3 {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  li {
    padding: 0 1rem;
  }
}


  .splide__slide {
    display: grid;
    place-content: center;
    padding: 0 1em;

    & picture,
    & img {
      margin: 0 auto;
      object-fit: cover;
    }
  }

