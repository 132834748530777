label {
  display: block;
  margin-bottom: 5px;
}

select,
input[type=text],
input[type=email],
input[type=tel],
input[type=password],
input[type=number],
input[type=search],
input[type=textarea],
textarea {
  appearance: none;
  background-color: var(--wh-color);
  border: 2px solid transparent;
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow);
  font-family: inherit;
  font-size: var(--paragraph);
  height: auto;
  margin: 0 .6em 0 0;
  outline: none;
  padding: .6em 1.2em;
  width: 100%;

  &:focus {
    border: 2px solid var(--pri-color);
    box-shadow: var(--shadow-lg);
  }
}

input[type=submit] {
  appearance: none;
  background-color: var(--sec-color);
  border: 2px solid transparent;
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow);
  color: var(--f-color-inv);
  font-family: inherit;
  font-size: var(--paragraph);
  font-weight: var(--h-font-wght);
  margin: 0 .6em 0 0;
  outline: none;
  padding: .6em 1.2em;
  text-align: center;
  text-transform: uppercase;
  transition: all var(--dur) var(--trans);
  width: 100%;

  &:focus,
  &:hover {
    border: 2px solid var(--pri-color);
    box-shadow: var(--shadow-lg);
    font-style: italic;
  }
}

textarea {
  height: 12rem;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--pri-color);
  transition: background-color 5000s ease-in-out 0s;
}

form {
  background-color: var(--color-p-2);
  margin-bottom: 0;
  margin-top: 2rem;
  padding: 1.5em;

  .form--row {
    display: grid;
    grid-template-columns: 1fr;

    & div{margin-bottom: 1.5em;}

  }
}

@media screen and (min-width: 797px) {
  form {
    margin-top: 1rem;
    .form--row {
      grid-template-columns: 1fr 1fr;

      & div {
        margin-right: 1rem;
      }

      & div:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.inline--form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  div {
    flex: 1 1 calc(50% - 0.5rem);
  }
}

@media (max-width: 768px) {
  .inline--form {
    flex-direction: column;
  }

  .inline--form div {
    flex: 1 1 100%;
  }
}

.policy--link {
  font-size: .85em;
  margin: 1em .5em;
  a {
    font-size: inherit;
  }
}
