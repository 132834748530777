:is(ul) {
  list-style: none outside none;
  white-space: nowrap;
  margin-left: 0;

  & li {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
    word-break: keep-all;
    overflow-wrap: keep-all;
    white-space: normal;
  }

  & li:before {
    content: none;
  }
}

:is(ol) {
  list-style: decimal outside none;
  white-space: nowrap;
  margin-bottom: 1rem;
  margin-left: 0;
  counter-reset: ol-counter;

  & li {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
    word-break: keep-all;
    overflow-wrap: keep-all;
    white-space: normal;
    counter-increment: ol-counter;
  }

  & li:before {
    content: counter(ol-counter) ". ";
    color: var(--pri-color);
    font-weight: 700;
  }
}

:is(ol) {
  counter-reset: first-counter;
  list-style-type: none;
  position: relative;
  padding-left: 20px;

  & li {
    margin: 10px 0;
  }

  & li:before {
    content: counter(first-counter) '.';
    counter-increment: first-counter;
    position: absolute;
    left: 0px;
  }

  :is(ol) {
    counter-reset: second-counter;
    list-style-type: none;

    & li {
      padding-left: 15px;
    }

    & li:before {
      content: counter(second-counter, lower-alpha) '.';
      counter-increment: second-counter;
      list-style-type: none;
      left: 10px;
      position: absolute;
    }

    :is(ol) :is(ol) {
      counter-reset: third-counter;

      & li {
        padding-left: 15px;
      }

      & li:before {
        list-style-type: none;
        content: counter(third-counter, lower-roman) '.';
        counter-increment: third-counter;
      }
    }
  }
}

.breadcrumb ol {
  list-style: none;
  padding: 0;

  & li {
    display: inline-block;
    font-size: 12px;

    & a {
      text-decoration: none;
      font-size: inherit;
    }
  }

  & li::before {
    display: inline-block;
    content: '';
  }

  & li::after {
    display: inline-block;
    content: '/';
  }

  & li:last-of-type {
    &::after {
      content: "";
    }
  }
}
