.scrollup {
  background: var(--pri-color);
  border-radius: var(--bor-rad);
  bottom: 0;
  color: var(--wh-color);
  font-size: 1.5rem;
  line-height: 1;
  opacity: 1;
  padding: .25rem .45rem 0;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  transition: all .3s ease-in-out;
  z-index: 9999;

  &:hover {
    opacity: .7;
    transform: translateY(-.25em);
  }

  .icon {
    height: 1em;
    margin: 0;
    position: relative;
    top: 0;
    width: 1em;
  }
}
