hr {
  border: 0;
  border-bottom: var(--bor-width) solid var(--g-color-3);
  margin: 1em .2em 1.5em;
}

.visually--hidden {
  clip: rect(1px 1px 1px 1px);
  clip-path: inset(1px);
  display: block;
  height: 1px;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  width: 1px;
}

.is--hidden {
  display: none;
}

.block {
  background-color: var(--g-color);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow);
  color: var(--f-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .6rem;
  text-align: left;

  & img {
    margin-bottom: 0;
  }
}

.text--link--block {
  border: var(--bor);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-sm);
  color: var(--f-color);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 2rem;
  text-align: center;

  & a {
    background-color: var(--pri-color-drk);
    margin-top: auto;
  }
}

.four--image--block {
  margin-top: 2rem;
}

.flow>*+* {
  margin-top: 1em;
}

.s--wrapper {
  margin: 0 auto;
  max-width: var(--s-width, 80rem);
  padding: 0 1rem;
  width: 100%;
}

.m--wrapper {
  margin: 0 auto;
  max-width: var(--m-width, 80rem);
  padding: 0 1rem;
  width: 100%;
}

.l--wrapper {
  margin: 0 auto;
  max-width: var(--l-width, 80rem);
  padding: 0 1rem;
  width: 100%;
}

.complete--width {
  width: 100%;
}

.full--width {
  background-color: var(--g-color-1);
  margin-left: 50%;
  padding: 5vh 1rem;
  transform: translateX(-50%);
  width: 100vw;
}


.maximum--width {
  margin-left: 50%;
  padding: 0;
  transform: translateX(-50%);
  width: 100vw;

  & div.designrr--holder {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--s-width);
  }
}

.maximum--width.background--none {
  background-color: transparent;
}

.maximum--width.background--dark-navy {
  background-color: var(--sec-color);
  color: var(--f-color-inv);
}

.maximum--width.background--orange {
  background-color: var(--pri-color);
  color: var(--f-color);
}

.pri--bkgd {
  background-color: var(--pri-color);
}

.sec--bkgd {
  background-color: var(--sec-color);
}

.background--none {
  background-color: transparent;
}

.background--dark-navy {
  background-color: var(--sec-color);
  color: var(--f-color-inv);
}

.background--gold {
  background-color: var(--pri-color);
  color: var(--f-color);
}

.centered {
  display: grid;
  place-content: center;
}

.inline {
  display: flex;
}

.vertical--spacer--s {
  height: 1em;
}
.vertical--spacer--m {
  height: 2em;
}
.vertical--spacer--l {
  height: 3em;
}

.invert--text {
  & p {
    color: var(--wh-color);
  }
}

@media screen and (min-width: 797px) {
  .full--width {
    padding: 5vh 1rem;
  }
}

@media screen and (min-width: 1023px) {

  .four--image--block {
    margin-top: 3rem;
  }
}
