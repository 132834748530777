@font-face {
  font-display: swap;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/assistant-v19-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/assistant-v19-latin-600.woff2') format('woff2');
}

:root {
  --default-screen: 1080;

  --header-text: clamp(
    40px,
    calc((52 / (var(--default-screen) / 100)) * 1vw),
    50px
  );

  --heading-h1: clamp(
    38px,
    calc((42 / (var(--default-screen) / 100)) * 1vw),
    46px
  );
  --heading-h2: clamp(
    32px,
    calc((36 / (var(--default-screen) / 100)) * 1vw),
    40px
  );
  --heading-h3: clamp(
    24px,
    calc((28 / (var(--default-screen) / 100)) * 1vw),
    32px
  );
  --heading-h4: clamp(
    20px,
    calc((24 / (var(--default-screen) / 100)) * 1vw),
    28px
  );
  --heading-h5: clamp(
    18px,
    calc((20 / (var(--default-screen) / 100)) * 1vw),
    24px
  );
  --heading-h6: clamp(
    18px,
    calc((18 / (var(--default-screen) / 100)) * 1vw),
    20px
  );
  --paragraph: clamp(
    18px,
    calc((18 / (var(--default-screen) / 100)) * 1vw),
    20px
  );
  --small: clamp(
    11px,
    calc((1 / (var(--default-screen) / 100)) * 1vw),
    12px
  );
}

h1 {
  font-size: var(--heading-h1);
  margin-bottom: 1em;
  margin-top: 0;
}

h2 {
  font-size: var(--heading-h2);
  margin-bottom: .4em;
  margin-top: 1.2em;
}

h3 {
  font-size: var(--heading-h3);
  margin-bottom: .4em;
  margin-top: 1.2em;
}

h4 {
  font-size: var(--heading-h4);
  margin-bottom: .4em;
  margin-top: 1.2em;
}

h5 {
  font-size: var(--heading-h5);
  margin-bottom: .4em;
  margin-top: 1.2em;
}

h6 {
  font-size: var(--heading-h6);
  margin-bottom: .4em;
  margin-top: 1.2em;
}

p,
li,
a,
blockquote,
input,
button {
  font-size: var(--paragraph);
}

small,
.is--meta {
  font-size: var(--small);
}

body,
html {
  font-family: var(--b-font);
  font-style: normal;
  font-weight: var(--f-wght);
  letter-spacing: var(--l-spacing);
  text-rendering: optimizeLegibility;
  word-spacing: var(--w-spacing);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  --l-spacing: 0;
  --w-spacing: 0;
  color: inherit;
  font-family: var(--h-font);
  font-weight: var(--h-font-wght);
  letter-spacing: var(--l-spacing);
  line-height: var(--line-height-headings);
  text-align: var(--h-pos);
  word-spacing: var(--w-spacing);
}

p {
  line-height: var(--line-height);
  margin-bottom: 1em;
  white-space: normal;
  word-break: keep-all;

  &.is--indented {
    text-indent: var(--space-unit);
  }
}

small, .is--small {
  font-size: 80%;
  font-style: italic;

  & a {
    font-size: inherit;
  }
}

blockquote {
  background-color: var(--g-color-1);
  border-left: 5px solid var(--pri-color);
  border-radius: var(--bor-rad);
  color: var(--f-color);
  font-style: italic;
  font-weight: var(--f-wght);
  margin: 0 0 1rem;
  padding: var(--space-xxs);
}

.is--meta {
  opacity: .8;
}

.is--bold,
b,
strong {
  font-weight: bold;
}

.is--italic {
  font-style: italic;
}

.is--heading {
  font-family: var(--h-font);
  font-weight: var(--h-font-wght);
  letter-spacing: var(--l-spacing);
}

.is--paragraph--heading {
  font-family: var(--h-font);
  font-size: var(--heading-h5);
  font-weight: var(--h-font-wght);
  letter-spacing: var(--l-spacing);
  margin-bottom: .2em;
}


@media only screen and (max-width: 49.9rem) {
  body {
    text-rendering: optimizeSpeed;
  }
}
