.p-0 { padding: 0;}
.p-1 { padding: 0.5em;}
.p-2 { padding: 1em;}
.p-3 { padding: 1.5em;}
.p-4 { padding: 2em;}
.p-5 { padding: 2.5em;}
.p-6 { padding: 3em;}

.pt-0 { padding-top: 0;}
.pt-1 { padding-top: 0.5em;}
.pt-2 { padding-top: 1em;}
.pt-3 { padding-top: 1.5em;}
.pt-4 { padding-top: 2em;}
.pt-5 { padding-top: 2.5em;}
.pt-6 { padding-top: 3em;}

.pr-0 { padding-right: 0;}
.pr-1 { padding-right: 0.5em;}
.pr-2 { padding-right: 1em;}
.pr-3 { padding-right: 1.5em;}
.pr-4 { padding-right: 2em;}
.pr-5 { padding-right: 2.5em;}
.pr-6 { padding-right: 3em;}

.pb-0 { padding-bottom: 0;}
.pb-1 { padding-bottom: 0.5em;}
.pb-2 { padding-bottom: 1em;}
.pb-3 { padding-bottom: 1.5em;}
.pb-4 { padding-bottom: 2em;}
.pb-5 { padding-bottom: 2.5em;}
.pb-6 { padding-bottom: 3em;}

.pl-0 { padding-left: 0;}
.pl-1 { padding-left: 0.5em;}
.pl-2 { padding-left: 1em;}
.pl-3 { padding-left: 1.5em;}
.pl-4 { padding-left: 2em;}
.pl-5 { padding-left: 2.5em;}
.pl-6 { padding-left: 3em;}
