audio,
iframe,
img,
object,
picture,
video {
  display: block;
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

img, picture {
  border-radius: var(--bor-rad);
  margin: 0 auto 1rem;
}

img {
  border-radius: var(--bor-rad);
  font-family: var(--b-font);
  font-size: 14px;
  line-height: inherit;
  position: relative;
  text-align: center;
}

img::before {
  content: "We're sorry, the image below is broken";
  display: block;
  margin-bottom: 10px;
}

img::after {
  content: '(url: "attr(src) ")';
  display: block;
  font-size: .75em;
  margin-top: 6px;
}

