.icon {
  fill: currentColor;
  height: 1em;
  margin-right: 0.5em;
  position: relative;
  top: 0.15em;
  width: 1em;
}

.social--icons {
  background-color: var(--wh-color);
  fill: var(--wh-color);
  height: 1.2em;
  margin-right: 1rem;
  width: 1.2em;
}
