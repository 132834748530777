.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.screen-reader-text:active,
.screen-reader-text:focus,
.screen-reader-text:hover {
  top: 5px;
  left: 5px;
  z-index: 100000;
  display: block;
  width: auto;
  height: auto;
  padding: 15px 23px 14px;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  color: var(--color-bkgd);
  text-decoration: none;
  background-color: var(--color-wh);
  clip: auto !important;
}
