.theme--switcher {
  cursor: pointer;
  height: 20px;
  margin: 0 auto;
  position: absolute;
  right: -1rem;
  top: 1rem;
  width: 50px;
  z-index: 999;

  .background {
    height: 20px;
    width: 50px;
  }

  .switch {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: -5px;
    transition: all .2s ease-in-out;
    width: 30px;

    & img {
      height: auto;
      position: absolute;
      transition: all .2s ease-in-out;
      width: 60%;
    }

    .sun {
      opacity: 1;
    }

    .moon {
      opacity: 0;
    }
  }

  & input {
    display: none;

    &:checked+.switch {
      .sun {
        opacity: 0;
      }

      .moon {
        opacity: 1;
      }
    }
  }
}
