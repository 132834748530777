:root {
  --bor-rad: 4px;
  --bor-width: 1px;
  --bor-color: var(--g-color-3);
  --h-font: 'Assistant', Sans-Serif;
  --b-font: 'Assistant', Sans-Serif;
  --l-spacing: 0.06em;
  --w-spacing: 0.08em;
  --h-pos: left;
  --f-wght: 200;
  --h-font-wght: 600;
  --line-height: calc(1.1em + 0.5rem);
  --line-height-list: 1.7;
  --line-height-headings: 1.2;
  --dur: 0.35s;
  --trans: ease-in-out;
  --s-width: 110ch;
  --m-width: 130ch;
  --l-width: 150ch;
  --inner-shadow: 0px 1px 1px var(--sec-color);
  --shadow-sm: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11);
  --shadow: 0 1px 1px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.10), 0 4px 4px rgba(0, 0, 0, 0.10);
  --shadow-md: 0 1px 1px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.10), 0 4px 4px rgba(0, 0, 0, 0.10), 0 6px 8px rgba(0, 0, 0, 0.10);
  --shadow-lg: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09), 0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
  0 32px 16px rgba(0, 0, 0, 0.09);
}

